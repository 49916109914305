import * as React from "react";
import { Layout } from "../components/Layout";
import { PatientEducation } from "../components/PatientEducation";

export default function PatientEducationPage() {
  return (
    <Layout>
      <PatientEducation />
    </Layout>
  );
}
