import React from "react";
import cuid from "cuid";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Typography, Grid, Container } from "@mui/material";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import ReactPlayer from "react-player/youtube";
import Iframe from "react-iframe";

import { Section } from "../Section";

import { StyledPatientEducation } from "./PatientEducation.styled";

const videoobj = [
  {
    id: cuid(),
    url: `https://youtu.be/pph7wixePA0`,
  },
  {
    id: cuid(),
    url: `https://youtu.be/pttMB5Gslg0`,
  },
  {
    id: cuid(),
    url: `https://youtu.be/t_r4Zt7QqqY`,
  },
  {
    id: cuid(),
    url: `https://youtu.be/ztT_9q0fM08`,
  },
  {
    id: cuid(),
    url: `https://youtu.be/lmmgnRhPUOg`,
  },
  {
    id: cuid(),
    url: `https://youtu.be/_8QBPPe0IR0`,
  },
  {
    id: cuid(),
    url: `https://youtu.be/RBjWWM5gJcw`,
  },
];
const PatientEducationContent = ({ children }) => {
  return (
    <StyledPatientEducation>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Interactive
            </Typography>
            {/* <Typography variant="variant="h5"" align="center" mb={2}>
              <i>
                "Complete Hand Surgery - Care from Reconstruction to
                Rejuvenation"
              </i>
            </Typography> */}

            <Box display="flex" justifyContent="center" mb={4}>
              <Box>
                <Typography variant="h5">Instructions</Typography>
                <ul>
                  <li>Select a topic you would like to learn more about</li>
                  <li>Each topic has an animated slide show</li>
                  <li>
                    Choose to view a slide show animation with or without audio
                    narration
                  </li>
                  <li>
                    Download or print a brochure about each topic for future
                    reference
                  </li>
                  <li>
                    Use the quick links in the top bar to view additional topics
                    or start over
                  </li>
                </ul>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" mb={8}>
              <Iframe
                url="https://www.thejoynerhand.com/patient-education.html"
                width="720px"
                height="500px"
                id="vm"
                className="viewMedica"
                display="initial"
                position="relative"
                allowFullScreen
              />
            </Box>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="sm">
          <Box py={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Lectures</strong>
                </Typography>
                <Box mb={4}></Box>
                <Box display="flex" justifyContent="center">
                  <Box>
                    {videoobj.map((vid) => (
                      <Grid container>
                        <Grid item xs={12}>
                          <Box sx={{ pb: `20px` }}>
                            <ReactPlayer url={vid.url} />
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledPatientEducation>
  );
};

const PatientEducation = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "hero-patient-education.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="common.white">
              <strong>Patient Education</strong>
            </Typography>
            {/* <Typography variant="h4" align="center" color="secondary">
              Your hands deserve it
            </Typography> */}
          </Box>
        </Box>
      </BgImage>
      <PatientEducationContent />
    </>
  );
};

export default PatientEducation;
